import { IAuthenticationResult } from '../models/authentication-result.model';

export function mapAuthenticationResult(authResult: any = {}): IAuthenticationResult {
  const {
    authority = '',
    uniqueId = '',
    tenantId = '',
    scopes = [],
    account = undefined,
    idToken = '',
    idTokenClaims = '',
    accessToken = '',
    fromCache = '',
    expiresOn = undefined,
    correlationId = '',
    requestId = '',
    extExpiresOn = undefined,
    familyId = '',
    tokenType = '',
    state = '',
    cloudGraphHostName = '',
    msGraphHost = '',
    fromNativeBroker = undefined
  } = authResult;

  return {
    authority,
    uniqueId,
    tenantId,
    scopes,
    account,
    idToken,
    idTokenClaims,
    accessToken,
    fromCache,
    expiresOn,
    correlationId,
    requestId,
    extExpiresOn,
    familyId,
    tokenType,
    state,
    cloudGraphHostName,
    msGraphHost,
    fromNativeBroker
  };
}