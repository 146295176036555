import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IAssignedSaoOptionDto } from 'src/app/models/sao-option-dto.model';
import { ISaoOption } from 'src/app/models/osdu/sao-option.model';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { OptionType } from 'src/app/models/osdu/sao-record.model';
import { IAppState } from 'src/app/store/reducers';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';
import { ISaoRecordSource } from '../table/table.component';

@Component({
  selector: 'sao-map-options',
  templateUrl: './map-options.component.html',
  styleUrls: ['./map-options.component.scss']
})
export class MapOptionsComponent implements OnInit {

  saoRecord!: ISaoRecordTableDataSource;
  plannedOptions: IAssignedSaoOptionDto[] = [];
  acquiredOptions: IAssignedSaoOptionDto[] = [];
  public optionType = OptionType;
  plannedOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();
  acquiredOptsDataSource: MatTableDataSource<IAssignedSaoOptionDto> = new MatTableDataSource<IAssignedSaoOptionDto>();
  wellName!: string;
  private saoOptions: ISaoOption[] = [];
  private saoOptionsCacheState$: Observable<ISaoOptionsCacheState>;
  showTables: boolean = false;

  @Input()
  recordData!: Observable<ISaoRecordSource>;

  private subscriptions: Subscription[] = [];

  constructor(private store: Store<IAppState>) {
    this.saoOptionsCacheState$ = store.pipe(select('saoOptionsCacheState'));
  }

  ngOnInit(): void {
    this.getSaoOptions();
    this.getAssignedSaoOptions();
  }

  getSaoOptions() {
    let sub = this.saoOptionsCacheState$.subscribe(state => {
      if (!state.optionsLoading) {
        this.saoOptions = state.options;
      }
    });
    this.subscriptions.push(sub);
  }

  getAssignedSaoOptions() {
    let sub = this.recordData.subscribe(recordSource => {
      if (recordSource.saoRecordTableDataSource.data !== null && this.saoOptions.length > 0) {
        this.showTables = true;
        let assignedSaoOptions = recordSource?.saoRecordTableDataSource?.data.assignedSaoOptions;
        let assignedSaoOptionDto = assignedSaoOptions?.map(option => {
          let optionName = this.saoOptions?.find(opt => opt.id === option.optionId)?.data.saoOptionName;
          return <IAssignedSaoOptionDto><unknown>{
            ...option,
            optionName: optionName
          }
        });
        this.plannedOptions = assignedSaoOptionDto.filter(option => option.optionType === this.optionType.Planned);
        this.acquiredOptions = assignedSaoOptionDto.filter(option => option.optionType === this.optionType.Acquired);
        this.plannedOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(this.plannedOptions);
        this.acquiredOptsDataSource = new MatTableDataSource<IAssignedSaoOptionDto>(this.acquiredOptions);

        this.wellName = this.getWellName(recordSource.saoRecordTableDataSource);
      }
    });
    this.subscriptions.push(sub);
  }

  private getWellName(record: ISaoRecordTableDataSource): string {
    let wellName = record?.data?.permittedLegalWellName ? `${record?.data?.permittedLegalWellName} / ${record?.data?.externalWellName}`
    : `${record?.data?.externalWellName}`;
    return wellName;
  }

  plannedOptColumns = [
    {
      columnDef: "plannedOptions",
      header: "Planned"
    }
  ];

  displayedPlannedOptCols = this.plannedOptColumns.map(c => c.columnDef);

  acqOptionsColumns = [
    {
      columnDef: "acquiredOptions",
      header: "Acquired"
    }
  ];

  displayedAcqOptCols = this.acqOptionsColumns.map(c => c.columnDef);
}
