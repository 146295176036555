import { SelectionModel } from '@angular/cdk/collections';
import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ISaoOption } from 'src/app/models/osdu/sao-option.model';
import { IAssignedSaoOption, ISaoRecord, OptionType } from 'src/app/models/osdu/sao-record.model';
import { IAppState } from 'src/app/store/reducers';
import { ISaoOptionsCacheState } from 'src/app/store/reducers/fetch-sao-options.reducer';
import { FilePreviewComponent } from '../../file-preview/file-preview.component';
import { previewViewerAllowExt } from 'src/app/util/constants.util';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { ISaoRecordDataSourceState } from '../../filter/filter.component';

@Component({
  selector: 'sao-sao-records',
  templateUrl: './search-records.component.html',
  styleUrls: ['./search-records.component.scss']
})
export class SearchRecordsComponent implements AfterViewInit, OnDestroy {

  private subscriptions: Subscription[] = [];

  private saoOptionsLoading: boolean = false;
  private filterLoading: boolean = false;

  saoRecordsDataSource: MatTableDataSource<ISaoRecordTableDataSource>;
  acqOptsDataSource: MatTableDataSource<IAssignedSaoOption>;
  plannedOptsDataSource: MatTableDataSource<IAssignedSaoOption>;

  allSaoOptionsData: ISaoOption[] = [];
  private saoOptionsCacheState$: Observable<ISaoOptionsCacheState>;

  sortActive: string = 'developmentArea';
  sortDirection: SortDirection = 'asc';

  saoRecordsTableSelection: SelectionModel<ISaoRecordTableDataSource> = new SelectionModel<ISaoRecordTableDataSource>(false, []);

  @ViewChild(MatPaginator) private readonly paginator!: MatPaginator;
  @ViewChild(MatSort) public readonly sort!: MatSort;

  private filterDataSubject: BehaviorSubject<ISaoRecordDataSourceState> = new BehaviorSubject<ISaoRecordDataSourceState>(<ISaoRecordDataSourceState>{
    records: [],
    loading: true
  });

  constructor(private datePipe: DatePipe,
    private router: Router,
    private store: Store<IAppState>,
    private cd: ChangeDetectorRef,
    private matDialog: MatDialog) {

    this.saoOptionsCacheState$ = store.pipe(select('saoOptionsCacheState'));

    this.saoRecordsDataSource = new MatTableDataSource<ISaoRecordTableDataSource>();
    this.acqOptsDataSource = new MatTableDataSource<IAssignedSaoOption>();
    this.plannedOptsDataSource = new MatTableDataSource<IAssignedSaoOption>();
  }

  ngAfterViewInit(): void {
    this.getSaoOptions();
    this.getRecordsData();
    this.addDefaultSorting();

    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  isDataLoading(): boolean {
    return (this.saoOptionsLoading
      || this.filterLoading);
  }

  getSaoOptions() {
    let sub = this.saoOptionsCacheState$.subscribe(state => {
      this.allSaoOptionsData = state.options;
      this.saoOptionsLoading = state.optionsLoading;
      if (!this.saoOptionsLoading) {
        let toSort = [...this.allSaoOptionsData];
        toSort.sort((a, b) => (a.data.saoOptionName < b.data.saoOptionName) ? -1 : 1);
        this.allSaoOptionsData = toSort;
      }
    });
    this.subscriptions.push(sub);
  }

  onFilterData(recordsDataSourceState: ISaoRecordDataSourceState) {
    this.filterDataSubject.next(recordsDataSourceState);
  }

  addDefaultSorting(active: string = 'developmentArea', direction: SortDirection = 'asc') {
    let sortState = this.sort;
    sortState.active = active;
    sortState.direction = direction;

    this.announceSortChange(sortState);
  }

  getRecordsData() {
    this.getRecordsDataObservable().subscribe({
      next: (recordsState) => {
        this.filterLoading = recordsState.loading;
        this.updateSaoRecordsTable(recordsState.records);
        this.onSaoRecordsRowClick(this.saoRecordsDataSource.data[0]);
      },
      error: (err) => {
        console.error(err);
      },
      complete: () => {
        console.log('SA&O records updated')
      }
    });
  }

  private getRecordsDataObservable(): Observable<ISaoRecordDataSourceState> {
    return this.filterDataSubject.asObservable();
  }

  onSaoRecordsRowClick(record: ISaoRecordTableDataSource) {
    this.saoRecordsTableSelection.select(record);

    let acqOpts: IAssignedSaoOption[] = [];
    let plannedOpts: IAssignedSaoOption[] = [];

    if (record?.data?.assignedSaoOptions) {
      acqOpts = record.data.assignedSaoOptions.filter(option => option.optionType == OptionType.Acquired);
      plannedOpts = record.data.assignedSaoOptions.filter(option => option.optionType == OptionType.Planned);
    }

    this.acqOptsDataSource = new MatTableDataSource<IAssignedSaoOption>(acqOpts);
    this.plannedOptsDataSource = new MatTableDataSource<IAssignedSaoOption>(plannedOpts);
  }

  setSearchPaginatorToFirstPage() {
    if (this.saoRecordsDataSource.paginator) {
      this.saoRecordsDataSource.paginator.firstPage();
    }
  }

  updateSaoRecordsTable(data: ISaoRecordTableDataSource[]) {
    this.saoRecordsDataSource = new MatTableDataSource<ISaoRecordTableDataSource>(data);
    this.saoRecordsDataSource.paginator = this.paginator;
    this.saoRecordsDataSource.paginator.firstPage();
    this.selectFirstRowOnSearchTable();
    this.addDefaultSorting(this.sortActive, this.sortDirection);

    this.cd.detectChanges();
  }

  selectFirstRowOnSearchTable(row: ISaoRecordTableDataSource = this.saoRecordsDataSource.data[0]) {
    this.onSaoRecordsRowClick(row);
  }

  announceSortChange(sortState: Sort) {
    if (!sortState.active || sortState.direction === '') {
      this.addDefaultSorting();
      return;
    }

    let unsorted = this.saoRecordsDataSource.data;
    const sorted = unsorted.slice().sort((a, b) => {
      const isAsc = sortState.direction === 'asc';
      switch (sortState.active) {
        case 'developmentArea': {
          return this.compare(a.data.developmentArea, b.data.developmentArea, isAsc);
        }
        case 'subDevelopmentArea': {
          return this.compare(a.data.subDevelopmentArea, b.data.subDevelopmentArea, isAsc);
        }
        case 'padName': {
          return this.compare(a.data.padName, b.data.padName, isAsc);
        }
        case 'externalWellName': {
          return this.compare(a.data.preferredWellName, b.data.preferredWellName, isAsc);
        }
        case 'ApiNumber': {
          return this.compare(a.data.apiPropertyId, b.data.apiPropertyId, isAsc);
        }
        case 'targetFormation': {
          return this.compare(a.data.targetFormation, b.data.targetFormation, isAsc);
        }
        case 'fracStartDate': {
          let fracStartDateA = this.getComparisonDate(a);
          let fracStartDateB = this.getComparisonDate(b);
          return this.compare(fracStartDateA, fracStartDateB, isAsc);
        }
        case 'spudDate': {
          let spudDateA = this.getComparisonDate(a);
          let spudDateB = this.getComparisonDate(b);
          return this.compare(spudDateA, spudDateB, isAsc);
        }
        case 'forecastedPop': {
          let forecastedPopA = this.getComparisonDate(a);
          let forecastedPopB = this.getComparisonDate(b);
          return this.compare(forecastedPopA, forecastedPopB, isAsc);
        }
        default: {
          return 0;
        }
      }
    });
    this.sortActive = sortState.active;
    this.sortDirection = sortState.direction;

    this.updateSortedSaoRecordsTable(sorted);
  }

  private compare(a: string | null, b: string | null, isAsc: boolean): number {
    let sortDirection = isAsc ? 1 : -1;
    let valueA = a === null ? '' : a?.toLowerCase();
    let valueB = b === null ? '' : b?.toLowerCase();

    let compareResult = valueA?.localeCompare(valueB);

    return compareResult * sortDirection;
  }

  private getComparisonDate(saoRecord: ISaoRecordTableDataSource): string | null {
    return saoRecord.data.spudDate !== null && this.dateIsValid(new Date(saoRecord.data.spudDate)) ?
      new Date(saoRecord.data.spudDate).toISOString().trim() : null;
  }

  private dateIsValid(date: Date) {
    return !Number.isNaN(new Date(date).getTime());
  }

  updateSortedSaoRecordsTable(data: ISaoRecordTableDataSource[]) {
    this.saoRecordsDataSource = new MatTableDataSource<ISaoRecordTableDataSource>(data);
    this.saoRecordsDataSource.paginator = this.paginator;

    this.cd.detectChanges();
  }

  @Output() saoRecordOutput = new EventEmitter<ISaoRecord>();
  editRowClicked(event: Event, row: ISaoRecord) {
    this.router.navigate(['edit', { saoRecordId: row.id }]);
    this.saoRecordOutput.emit(row);
  }

  GetFilesCount(row: IAssignedSaoOption): number {
    let counter = 0;
    if (row?.files.length > 0) {
      row.files.forEach(file => {
        let countFiles = file.fileVersions.length;
        counter += countFiles;
      });
      return counter;
    }
    else {
      return counter;
    }
  }

  openFilePreview(event: Event, fileData: any) {
    this.matDialog.open(FilePreviewComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      data: {
        file: {
          fileName: fileData.data.DatasetProperties.FileSourceInfo.Name,
          fileType: fileData.data.ExtensionProperties.FileContentsDetails.FileType,
          signedUrl: fileData.data.DatasetProperties.FileSourceInfo.SignedUrl
        }
      }
    });
  }

  checkFileExtension(fileType: string): boolean {
    if (previewViewerAllowExt.includes(fileType.toUpperCase())) {
      return true;
    }
    return false;
  }

  stringIsDate(dateString: string): boolean {
    let result = Date.parse(dateString);
    if (isNaN(result)){
      return false;
    }
    return true;
  }

  plannedOptColumns = [
    {
      columnDef: "saoOptioName",
      header: "Planned SA&O",
      cell: (option: IAssignedSaoOption) =>
        `${(this.allSaoOptionsData.find(opt => opt.id == option.optionId))?.data.saoOptionName}`
    }
  ];

  displayedPlannedOptCols = this.plannedOptColumns.map(c => c.columnDef);

  acqOptionsColumns = [
    {
      columnDef: "saoOptioName",
      header: "Acquired SA&O",
      cell: (option: IAssignedSaoOption) =>
        `${(this.allSaoOptionsData.find(opt => opt.id == option.optionId))?.data.saoOptionName}`
    }
  ];

  displayedAcqOptCols = this.acqOptionsColumns.map(c => c.columnDef);

  recordsColumns = [
    {
      columnDef: "actions",
      header: "",
      cell: (record: ISaoRecordTableDataSource) => { },
    },
    {
      columnDef: "developmentArea",
      header: "Dev Area",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.developmentArea}`
    },
    {
      columnDef: "subDevelopmentArea",
      header: "Sub Dev Area",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.subDevelopmentArea}`
    },
    {
      columnDef: "padName",
      header: "Pad Name",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.padName}`
    },
    {
      columnDef: "externalWellName",
      header: "Well Name",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.permittedLegalWellName ?? record.data.externalWellName}`
    },
    {
      columnDef: "ApiNumber",
      header: "API / Property ID",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.apiNumber ?? record.data.externalPropertyId}`
    },
    {
      columnDef: "targetFormation",
      header: "Tgt Formation",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.targetFormation}`
    },
    {
      columnDef: "fracStartDate",
      header: "Frac start date",
      cell: (record: ISaoRecordTableDataSource) =>
        record.data.fracStartDate !== null && this.stringIsDate(record.data.fracStartDate) ? this.datePipe.transform(`${record.data.fracStartDate}`, 'yyyy-MM-dd') : ''
    },
    {
      columnDef: "spudDate",
      header: "Spud date",
      cell: (record: ISaoRecordTableDataSource) =>
        record.data.spudDate !== null && this.stringIsDate(record.data.spudDate) ? this.datePipe.transform(`${record.data.spudDate}`, 'yyyy-MM-dd') : ''
    },
    {
      columnDef: "forecastedPop",
      header: "Forecasted POP Date",
      cell: (record: ISaoRecordTableDataSource) =>
        record.data.forecastedPop !== null && this.stringIsDate(record.data.forecastedPop) ? this.datePipe.transform(`${record.data.forecastedPop}`, 'yyyy-MM-dd') : ''
    }
  ];

  displayedRecordsColumns = this.recordsColumns.map(c => c.columnDef);
}
