import { environment } from "src/environments/environment";
import { IBasin } from "../models/osdu/basin.model";
import { IField } from "../models/osdu/field.model";
import { ISaoOption } from "../models/osdu/sao-option.model";
import { ISaoRecordTableDataSource } from "../models/sao-record-search-table.model";
import { ITargetFormation } from "../models/osdu/target-formation.model";
import { formatTgtFormation } from "./helpers.util";
import { OptionType } from "../models/osdu/sao-record.model";

export class FilterParams {
  constructor(public selectedBasin: IBasin | undefined,
    public allFieldsOsduRecords: IField[],
    public selectedField: IField | undefined,
    public selectedSubFields: IField[],
    public selectedTargetFormations: ITargetFormation[],
    public selectedSaoOptions: ISaoOption[],
    public filterByFiles: boolean,
    public filterByActivityType: string[]) { }
}

export class RecordsFilter {
  private saoRecordsdata: ISaoRecordTableDataSource[];

  constructor(saoRecords: ISaoRecordTableDataSource[]) {
    this.saoRecordsdata = saoRecords;
  }

  private filterByBasin(selectedBasin: IBasin | undefined, allFieldsOsduRecords: IField[]): this {
    if (selectedBasin === undefined) {
      return this;
    }
    else {
      let fields = allFieldsOsduRecords.filter(field => field.data.geoContexts[0]?.basinId == selectedBasin.id);
      let filteredData: ISaoRecordTableDataSource[] = [];

      this.saoRecordsdata.forEach(record => {
        if (fields.findIndex(devArea => devArea.data?.fieldName == record.data.developmentArea) > -1) {
          filteredData.push(record);
        }
      })

      this.saoRecordsdata = filteredData;
    }
    return this;
  }

  private filterByDevArea(selectedField: IField | undefined): this {
    if (selectedField === undefined) {
      return this;
    }
    else {
      let filteredFieldOsduRecords = this.saoRecordsdata.filter(record => record.data.developmentArea == selectedField.data?.fieldName);
      this.saoRecordsdata = filteredFieldOsduRecords;
    }
    return this;
  }

  private filterBySubDevAreas(selectedSubFields: IField[]): this {
    if (selectedSubFields === undefined) {
      return this;
    }
    if (selectedSubFields.length > 0) {
      let selectedSubFieldsNames = selectedSubFields.map(field => field.data?.fieldName);
      let filteredSubFieldOsduRecords = this.saoRecordsdata.filter((record) => selectedSubFieldsNames.indexOf(record.data.subDevelopmentArea) > -1);
      this.saoRecordsdata = filteredSubFieldOsduRecords;
    }

    return this;
  }

  private filterByTargetFormations(selectedTargetFormations: ITargetFormation[]): this {
    if (selectedTargetFormations === undefined) {
      return this;
    }
    if (selectedTargetFormations.length > 0) {
      let selectedTargetFormationsNames = selectedTargetFormations.map(
        formation => formatTgtFormation(formation.data.Name));
      this.saoRecordsdata = this.saoRecordsdata.filter(record => selectedTargetFormationsNames.indexOf(record.data.targetFormation) > -1);
    }

    return this;
  }

  private filterBySaoOptions(selectedSaoOptions: ISaoOption[]): this {
    if (selectedSaoOptions === undefined) {
      return this;
    }
    if (selectedSaoOptions.length > 0) {
      let selectedOptionsIds = selectedSaoOptions.map(option => option.id);
      this.saoRecordsdata = this.saoRecordsdata.filter(record => record.data.assignedSaoOptions?.some(option => selectedOptionsIds.indexOf(option.optionId) > -1));
    }

    return this;
  }

  private filterByAttachedFiles(filterByFiles: boolean): this {
    if (filterByFiles) {
      this.saoRecordsdata = this.saoRecordsdata.filter(
        record => record.data.assignedSaoOptions.every(option => option.files.length === 0));
    }

    return this;
  }

  private filterByActivityTypes(activityTypes: string[]): this {
    if (activityTypes === undefined) {
      return this;
    }
    if (activityTypes?.length > 0 && activityTypes?.length === 1) {
      if (activityTypes[0] === OptionType.Planned || activityTypes[0] === OptionType.Acquired) {
        this.saoRecordsdata = this.saoRecordsdata.filter(
          record => record.data.assignedSaoOptions?.every(
            option => option.optionType === activityTypes[0]
          )
        );
      }
    }

    if (activityTypes?.length > 0 && activityTypes?.length === 2) {
      this.saoRecordsdata = this.saoRecordsdata.filter(
        record => activityTypes.every(
          optionType => record.data.assignedSaoOptions.some(
            option => option.optionType === optionType))
      );
    }

    return this;
  }

  private getFilteredRecords(): ISaoRecordTableDataSource[] {
    return this.saoRecordsdata;
  }

  filter(filterParams: FilterParams
  ): ISaoRecordTableDataSource[] {

    return this.filterByBasin(filterParams.selectedBasin, filterParams.allFieldsOsduRecords)
      .filterByDevArea(filterParams.selectedField)
      .filterBySubDevAreas(filterParams.selectedSubFields)
      .filterByTargetFormations(filterParams.selectedTargetFormations)
      .filterBySaoOptions(filterParams.selectedSaoOptions)
      .filterByAttachedFiles(filterParams.filterByFiles)
      .filterByActivityTypes(filterParams.filterByActivityType)
      .getFilteredRecords();
  }
}
