import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISaoRecordDataSourceState } from '../filter/filter.component';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { ISaoRecordSource } from './table/table.component';

@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  dataWells!: Observable<ISaoRecordDataSourceState>;
  recordSource!: Observable<ISaoRecordSource>;
  mapClickedWell!: Observable<ISaoRecordTableDataSource>;
  reloadMap!: Observable<boolean>;

  private recordsDataSourceSubject: BehaviorSubject<ISaoRecordDataSourceState>
    = new BehaviorSubject<ISaoRecordDataSourceState>(<ISaoRecordDataSourceState>{
      records: [],
      loading: true
    });

  private recordDataSubject: BehaviorSubject<ISaoRecordSource>
    = new BehaviorSubject<ISaoRecordSource>(<ISaoRecordSource>{
      saoRecordTableDataSource: <ISaoRecordTableDataSource><unknown>{ data: null },
      clickSource: 'MAP'
    });

  private mapClickedWellSubject: BehaviorSubject<ISaoRecordTableDataSource>
    = new BehaviorSubject<ISaoRecordTableDataSource>(<ISaoRecordTableDataSource><unknown>{ data: null });

  private reloadMapSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  ngOnInit(): void {
    this.dataWells = this.getSaoRecordDataSource();
    this.recordSource = this.getRecordData();
    this.mapClickedWell = this.getMapClickedWellData();
    this.reloadMap = this.reloadMapData();
  }

  onFilterData(recordsDataSource: ISaoRecordDataSourceState) {
    this.recordsDataSourceSubject.next(recordsDataSource);
  }

  private getSaoRecordDataSource(): Observable<ISaoRecordDataSourceState> {
    return this.recordsDataSourceSubject.asObservable();
  }

  onTableRowClick(recordData: ISaoRecordSource) {
    this.recordDataSubject.next(recordData);
  }

  private getRecordData(): Observable<ISaoRecordSource> {
    return this.recordDataSubject.asObservable();
  }

  onMapWellClick(wellData: ISaoRecordTableDataSource) {
    this.mapClickedWellSubject.next(wellData);
  }

  private getMapClickedWellData(): Observable<ISaoRecordTableDataSource> {
    return this.mapClickedWellSubject.asObservable();
  }

  onReloadMap(reload: boolean) {
    this.reloadMapSubject.next(reload);
  }

  private reloadMapData(): Observable<boolean> {
    return this.reloadMapSubject.asObservable();
  }
}
