import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from './shared/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module'
import { CalAngularModule } from '@cvx/cal-angular';
import { environment } from 'src/environments/environment';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { StoreModule } from '@ngrx/store';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { SearchRecordsComponent } from './components/sao-records/search/search-records.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SorMenuCardComponent } from './components/sor-menu-card/sor-menu-card.component';
import { LoginComponent } from './components/login/login.component';
import { AuthGuardService } from './shared/authorization-guard.service';
import { AboutComponent } from './components/about/about.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { AddRecordsComponent } from './components/sao-records/add/add-records.component';
import { EditRecordComponent } from './components/sao-records/edit/edit-record.component';
import { FetchRecordsEffects } from './store/effects/fetch-records.effects';
import { EffectsModule } from '@ngrx/effects'
import { reducers } from './store/reducers';
import { FetchBasinsEffects } from './store/effects/fetch-basins.effects';
import { FetchFieldsEffects } from './store/effects/fetch-fields.effects';
import { FetchFormationsEffects } from './store/effects/fetch-formations.effects';
import { FetchSaoOptionsEffects } from './store/effects/fetch-sao-options.effects';
import { FetchTargetFormationsEffects } from './store/effects/fetch-tgt-formations.effects';
import { MatDialogModule } from '@angular/material/dialog';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SaoOptionsComponent } from './components/sao-records/shared/sao-options/sao-options.component';
import { SaoOptionRecordAddDialogComponent } from './components/sao-records/shared/sao-option-record-add-dialog/sao-option-record-add-dialog.component';
import { BrowseAttachedFilesComponent } from './components/browse-attached-files/browse-attached-files.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { SaoOptionsFileComponent } from './components/sao-records/shared/sao-options-files/sao-options-file.component';
import { FetchWellDetailsEffects } from './store/effects/fetch-wells-details.effect';
import { DataService } from './services/data-service.service';
import { SaoOptionsFileVersionComponent } from './components/sao-records/shared/sao-options-file-version/sao-options-file-version.component';
import { ChartComponent } from './components/dashboard/charts/charts.component';
import { AzureMapsComponent } from './components/dashboard/azure-maps/azure-maps.component';
import { FilterComponent } from './components/filter/filter.component';
import { MapOptionsDialogComponent } from './components/dashboard/map-options-dialog/map-options-dialog.component';
import { TableComponent } from './components/dashboard/table/table.component';
import { MapOptionsComponent } from './components/dashboard/map-options/map-options.component';
import { FetchAzureMapsSettingsEffects } from './store/effects/fetch-azure-maps-settings.effects';
import { AccessComponent } from './components/access/access.component';
import { FetchSubFieldsEffects } from './store/effects/fetch-sub-fields.effect';
import { MatTableExporterModule } from 'mat-table-exporter';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    MainMenuComponent,
    SearchRecordsComponent,
    FooterComponent,
    NavigationComponent,
    SorMenuCardComponent,
    LoginComponent,
    AboutComponent,
    AddRecordsComponent,
    EditRecordComponent,
    SaoOptionRecordAddDialogComponent,
    FileUploadComponent,
    SaoOptionsComponent,
    BrowseAttachedFilesComponent,
    FilePreviewComponent,
    GenericDialogComponent,
    SaoOptionsFileComponent,
    SaoOptionsFileVersionComponent,
    ChartComponent,
    AzureMapsComponent,
    FilterComponent,
    MapOptionsDialogComponent,
    TableComponent,
    MapOptionsComponent,
    AccessComponent
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatDialogModule,
    MatExpansionModule,
    MatPaginatorModule,
    NgxDocViewerModule,
    PdfJsViewerModule,
    MatTableExporterModule,
    EffectsModule.forRoot([
      FetchAzureMapsSettingsEffects,
      FetchRecordsEffects,
      FetchBasinsEffects,
      FetchFieldsEffects,
      FetchSubFieldsEffects,
      FetchFormationsEffects,
      FetchSaoOptionsEffects,
      FetchTargetFormationsEffects,
      FetchWellDetailsEffects
    ]),
    StoreModule.forRoot(reducers),
    CalAngularModule.forRoot({
      autoSignIn: false,
      popupForLogin: false,
      instance: "https://login.microsoftonline.com/",
      tenantId: "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
      clientId: environment.shaleTightSaoClientId,
      redirectUri: environment.redirectUrl,
      oidcScopes: [environment.dmSearchServiceScope],
      graphScopes: ["User.Read", "Directory.Read.All"],
      cacheLocation: "sessionStorage"
    }),
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG
    }),
    AppRoutingModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      name: 'SA&O Store'
    })
  ],
  providers: [
    AuthGuardService,
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true},
    [DatePipe],
    DataService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
