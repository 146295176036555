import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ISaoRecordDataSourceState } from '../../filter/filter.component';
import { ISaoRecordTableDataSource } from 'src/app/models/sao-record-search-table.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';

export type ClickSource = 'MAP' | 'TABLE';

export interface ISaoRecordSource {
  saoRecordTableDataSource: ISaoRecordTableDataSource,
  clickSource: ClickSource
}

@Component({
  selector: 'sao-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit, OnDestroy {

  @Input()
  recordsState!: Observable<ISaoRecordDataSourceState>;

  @Output()
  recordData: EventEmitter<ISaoRecordSource> = new EventEmitter<ISaoRecordSource>();

  @Input()
  clickedWellData!: Observable<ISaoRecordTableDataSource>;

  private subscriptions: Subscription[] = [];
  private saoRecords!: ISaoRecordTableDataSource[];
  resultsLength: number = 0;

  saoRecordsDataSource: MatTableDataSource<ISaoRecordTableDataSource>;
  saoRecordsTableSelection: SelectionModel<ISaoRecordTableDataSource> = new SelectionModel<ISaoRecordTableDataSource>(false, []);
  @ViewChild('recordPaginator') private readonly paginator!: MatPaginator;

  constructor(private cd: ChangeDetectorRef) {
    this.saoRecordsDataSource = new MatTableDataSource<ISaoRecordTableDataSource>();
  }

  ngAfterViewInit(): void {
    this.getDataRecords();
    this.getWellDataOnMapClicked();
    this.cd.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getDataRecords(): void {
    let sub = this.recordsState.subscribe(state => {
      this.saoRecordsTableSelection.clear();
      this.saoRecords = this.sortDataByDevAre(state.records);
      this.resultsLength = state.records.length;
      this.saoRecordsDataSource.data = this.saoRecords;
      this.saoRecordsDataSource.paginator = this.paginator;
    });
    this.subscriptions.push(sub);
  }

  onSaoRecordsRowClick(record: ISaoRecordTableDataSource, source: ClickSource) {
    this.saoRecordsTableSelection.select(record);
    let recordDataSource = <ISaoRecordSource>{
      saoRecordTableDataSource: record,
      clickSource: source
    };
    this.recordData.emit(recordDataSource);
  }

  getWellDataOnMapClicked() {
    let sub = this.clickedWellData.subscribe(well => {
      if (well.data !== null) {
        this.moveToRowByWell(well, 'MAP');
      }
    });
    this.subscriptions.push(sub);
  }

  private moveToRowByWell(wellData: ISaoRecordTableDataSource, source: ClickSource) {
    let index = this.saoRecordsDataSource.data.findIndex((well) => well.id === wellData.id);
    if (index > -1) {
      this.saoRecordsTableSelection.clear();
      let pageIndex = Math.floor(index / this.paginator.pageSize);
      this.paginator.pageIndex = pageIndex;
      this.paginator._changePageSize(this.paginator.pageSize);
      this.onSaoRecordsRowClick(wellData, source);
    }
  }

  private sortDataByDevAre(records: ISaoRecordTableDataSource[]): ISaoRecordTableDataSource[] {
    return records.slice().sort((a, b) => {
      return this.compare(a.data.developmentArea, b.data.developmentArea, true);
    });
  }

  private compare(a: string | null, b: string | null, isAsc: boolean): number {
    let sortDirection = isAsc ? 1 : -1;
    let valueA = a === null ? '' : a?.toLowerCase();
    let valueB = b === null ? '' : b?.toLowerCase();

    let compareResult = valueA?.localeCompare(valueB);

    return compareResult * sortDirection;
  }

  recordsColumns = [
    {
      columnDef: "developmentArea",
      header: "Dev Area",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.developmentArea}`
    },
    {
      columnDef: "subDevelopmentArea",
      header: "Sub Dev Area",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.subDevelopmentArea}`
    },
    {
      columnDef: "padName",
      header: "Pad Name",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.padName}`
    },
    {
      columnDef: "externalWellName",
      header: "Well Name",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.preferredWellName}`
    },
    {
      columnDef: "ApiNumber",
      header: "API / Property ID",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.apiPropertyId}`
    },
    {
      columnDef: "targetFormation",
      header: "Tgt Formation",
      cell: (record: ISaoRecordTableDataSource) => `${record.data.targetFormation}`
    }
  ];

  displayedRecordsColumns = this.recordsColumns.map(c => c.columnDef);

}
