<div class="container">
  <div class="filter"><sao-filter (filterData)="onFilterData($event)"></sao-filter></div>
  <div class="chart"><sao-chart [recordsState]="dataWells" (reloadMap)="onReloadMap($event)"></sao-chart></div>
  <div class="map">
    <sao-azure-maps [recordsState]="dataWells" [recordData]="recordSource" (clickedWell)="onMapWellClick($event)"
      [reloadMapData]="reloadMap">
    </sao-azure-maps>
  </div>
  <div class="options"><sao-map-options [recordData]="recordSource"></sao-map-options></div>
  <div class="sao-table">
    <sao-table [recordsState]="dataWells" (recordData)="onTableRowClick($event)" [clickedWellData]="mapClickedWell">
    </sao-table>
  </div>
</div>
