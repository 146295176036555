<div class="login-container">
    <ng-container *ngIf="(loginState$ | async) == 0">
        <div>
            <span class="sign-in-text">Please sign in to access SA&O SOR. </span>
        </div>
        <div>
            <button mat-raised-button class="login-button" (click)="onLogin()">Sign in</button>
        </div>
    </ng-container>
    <ng-container *ngIf="(loginState$ | async) != 0">
        <div>
            <span class="sign-in-text">Please wait while we sign you in... </span>
        </div>
    </ng-container>
</div>