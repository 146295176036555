import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { CalAngularService } from "@cvx/cal-angular";
import { NGXLogger } from "ngx-logger";
import { map, Observable } from "rxjs";

@Injectable()
export class AuthGuardService implements CanActivate {

    constructor(private calService: CalAngularService,
        private router: Router,
        private logger: NGXLogger) { }

    canActivate(): Observable<boolean> {
        return this.calService.isUserSignedIn().pipe(map(signedIn => {
            if(signedIn){
                return true;
            } else {
                this.router.navigate(['login']);
                return false;
            }
        }));
    }
}