import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { mergeMap, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { mapAuthenticationResult } from '../util/map-authentication-result.util';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {
  InterceptorSkipHeader = 'X-Skip-Interceptor';
  constructor(private calService: CalAngularService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes(environment.dmSearchServiceBaseUrl) || req.url.includes(environment.saoIngestionServiceBaseUrl)) {
      return this.interceptOsduServicesRequests(req, next);
    }
    return this.interceptFrontendApiRequests(req, next);
  };

  private interceptOsduServicesRequests(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.processRequest(req, next, [environment.dmSearchServiceScope]);
  }

  private interceptFrontendApiRequests(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.processRequest(req, next, [environment.shaleTightSaoScope]);
  }

  private processRequest(req: HttpRequest<any>, next: HttpHandler, scope: string[]): Observable<HttpEvent<any>> {
    return this.calService.getAADToken(scope, true).pipe(
      mergeMap(result => {
        let authResult = mapAuthenticationResult(result);
        let token = authResult.accessToken;
        let authRequest: any;
        if (req.headers.has(this.InterceptorSkipHeader)
          && req.headers.get(this.InterceptorSkipHeader)?.toLowerCase() === 'true') {
          let headers = req.headers.delete(this.InterceptorSkipHeader);
          authRequest = req.clone({ headers });
        } else {
          authRequest = token ? req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          }) : req;
        }
        return next.handle(authRequest);
      }));
  }

}
