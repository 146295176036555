import { IEnvironment } from "src/app/models/environment.model";
import { EnvironmentNames } from "src/app/util/environment.util";

export const environment: IEnvironment = {
    environmentName: EnvironmentNames.TEST1,
    production: false,
    redirectUrl: "https://shale-and-tight-surveillance-test1.azure.chevron.com",
    dmSearchServiceScope: "42d7e726-912f-4a7c-9ae8-110a2615ffb8/.default",
    dmSearchServiceBaseUrl: "https://dmsearchservice-r2-csdu-dev1-cvx.azurewebsites.net/v1/api",
    saoIngestionServiceBaseUrl: "https://sao-ingestion-adme-test-cvx.azurewebsites.net",
    osduSurveillanceRecordKind: "chevron:sao-osdu:surveillance-analysis-optimization:6.0.0",
    saoFrontendApiBaseUrl: "https://shale-and-tight-surveillance-api-test.azurewebsites.net",
    shaleTightSaoClientId: "d590f217-76d6-4544-9ac6-b45312f1f44e",
    shaleTightSaoScope: "d590f217-76d6-4544-9ac6-b45312f1f44e/.default",
    activityTypeFilterActive: false
}
