import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { AboutComponent } from './components/about/about.component';
import { BrowseAttachedFilesComponent } from './components/browse-attached-files/browse-attached-files.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoginComponent } from './components/login/login.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { AddRecordsComponent } from './components/sao-records/add/add-records.component';
import { EditRecordComponent } from './components/sao-records/edit/edit-record.component';
import { SearchRecordsComponent } from './components/sao-records/search/search-records.component';
import { AuthGuardService } from './shared/authorization-guard.service';
import { AccessComponent } from './components/access/access.component';

const routes: Routes = [
  {path: "access", component: AccessComponent, canActivate: [AuthGuardService]},
  {path: "browsedocuments", component: BrowseAttachedFilesComponent, canActivate: [AuthGuardService]},
  {path: "upload", component: FileUploadComponent, canActivate: [AuthGuardService]},
  {path: "add", component: AddRecordsComponent, canActivate: [AuthGuardService]},
  {path: "edit", component: EditRecordComponent, canActivate: [AuthGuardService]},
  {path: "records", component: SearchRecordsComponent, canActivate: [AuthGuardService]},
  {path: "dashboard", component: DashboardComponent, canActivate: [AuthGuardService]},
  {path: "home", component: MainMenuComponent, canActivate: [AuthGuardService]},
  {path: "login", component: LoginComponent},
  {path: "about", component: AboutComponent},
  {path: '', component: AppComponent, pathMatch: "full"},
  {path: "**", redirectTo: "login"}
]

@NgModule({
  declarations: [],
  imports: [[RouterModule.forRoot(routes)]],
  exports: [RouterModule]
})
export class AppRoutingModule { }
