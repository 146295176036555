<div mat-dialog-title class="dialog-title well-name">
  <span>{{wellName}}</span>
</div>
<div class="flex-container" *ngIf="showTables">
  <div class="planned">
    <mat-table class="mat-elevation-z8" [dataSource]="plannedOptsDataSource">
      <ng-container matColumnDef="{{plannedOptColumns[0].columnDef}}">
        <mat-header-cell *matHeaderCellDef>{{plannedOptColumns[0].header}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="row-container">
            <div class="option-name">{{element.optionName}}</div>
            <div class="date-acquisition">{{element.dataAcquisitionTime}}</div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedPlannedOptCols"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedPlannedOptCols"></mat-row>
    </mat-table>
  </div>
  <div class="acquired">
    <mat-table class="mat-elevation-z8" [dataSource]="acquiredOptsDataSource">
      <ng-container matColumnDef="{{acqOptionsColumns[0].columnDef}}">
        <mat-header-cell *matHeaderCellDef>{{acqOptionsColumns[0].header}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="row-container">
            <div class="option-name">{{element.optionName}}</div>
            <div class="date-acquisition">{{element.dataAcquisitionTime}}</div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedAcqOptCols"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedAcqOptCols"></mat-row>
    </mat-table>
  </div>
</div>
