<div id="search-records" class="content-container">
  <sao-filter (filterData)="onFilterData($event)"></sao-filter>
  <div class="cvx-light-gray-border">
    <table mat-table [dataSource]="saoRecordsDataSource" matSort (matSortChange)="announceSortChange($any($event))"
      class="mat-elevation-z8 records-table" aria-describedby="SA&O records search results">
      <ng-container *ngFor="let column of recordsColumns" [matColumnDef]="column.columnDef">
        <ng-container *ngIf="column.columnDef == 'actions'; else saoData">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{column.header}}
          </th>
          <td id="actions-cell" mat-cell *matCellDef="let row">
            <ng-container *ngIf="saoRecordsTableSelection.isSelected(row)">
              <a (click)="editRowClicked($event, row)">
                <span class="material-symbols-outlined" id="edit-button">edit_note</span>
              </a>
            </ng-container>
          </td>
        </ng-container>
        <ng-template #saoData>
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by {{column.header}}">
            {{column.header}}
          </th>
          <td mat-cell *matCellDef="let row">
            {{column.cell(row)}}
          </td>
        </ng-template>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedRecordsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedRecordsColumns;" (click)="onSaoRecordsRowClick(row)"
        [ngClass]="{'highlight-selected' : saoRecordsTableSelection.isSelected(row)}"></tr>
      <tr *matNoDataRow>
        <td *ngIf="!isDataLoading()" class="mat-cell" colspan="10">No data matching the filters criteria!</td>
      </tr>
    </table>
    <div class="loading-spinner" *ngIf="isDataLoading()">
      <mat-spinner></mat-spinner>
    </div>
  </div>
  <div class="cvx-light-gray-border-without-top">
    <mat-paginator [pageIndex]="0" [length]="100" [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"
      [showFirstLastButtons]=true>
    </mat-paginator>
  </div>
  <!-- planned table -->
  <div class="options-tables">
    <div class="planned">
      <table mat-table [dataSource]="plannedOptsDataSource" class="mat-elevation-z8"
        aria-describedby="Planned SA&O options assigned to selected well">
        <ng-container *ngFor="let column of plannedOptColumns" [matColumnDef]="column.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            {{column.header}}
          </th>
          <td mat-cell *matCellDef="let row">
            <div class="planned-item">{{column.cell(row)}}</div>
            <div class="planned-item">{{row.dataAcquisitionTime}}</div>
            <div class="planned-item" (mouseover)="mailTooltip.show()" (mouseout)="mailTooltip.hide()"
              #mailTooltip="matTooltip" matTooltip="{{row.addedBy}}" matTooltipPosition="above">
              {{row.addedBy}}
            </div>
            <div class="planned-item icons">
              <span class="material-symbols-outlined help-tooltip" (mouseover)="tooltip.show()"
                (mouseout)="tooltip.hide()" #tooltip="matTooltip"
                matTooltip="Select a record and click the edit icon on the left to edit the options"
                matTooltipPosition="above">
                help
              </span>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedPlannedOptCols"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedPlannedOptCols;"></tr>
      </table>
    </div>

    <!-- acquired table -->
    <div class="acquired">
      <table mat-table [dataSource]="acqOptsDataSource" class="mat-elevation-z8"
        aria-describedby="Acquired SA&O options assigned to selected well">
        <ng-container *ngFor="let column of acqOptionsColumns" [matColumnDef]="column.columnDef">
          <th mat-header-cell *matHeaderCellDef>
            {{column.header}}
          </th>
          <mat-accordion [multi]="false">
            <mat-expansion-panel [expanded]="false" mat-cell *matCellDef="let row" hideToggle="false">
              <mat-expansion-panel-header>
                <td>
                  <div class="acquired-item">{{column.cell(row)}}</div>
                  <div class="acquired-item"><mat-icon mat-list-icon class="icon-display">attach_file</mat-icon> {{
                    GetFilesCount(row) }}</div>
                  <div class="acquired-item">{{row.dataAcquisitionTime}}</div>
                  <div class="acquired-item" (mouseover)="mailTooltip.show()" (mouseout)="mailTooltip.hide()"
                    #mailTooltip="matTooltip" matTooltip="{{row.addedBy}}" matTooltipPosition="above">
                    {{row.addedBy}}
                  </div>
                  <div class="acquired-item icons">
                    <span class="material-symbols-outlined help-tooltip" (mouseover)="tooltip.show()"
                      (mouseout)="tooltip.hide()" #tooltip="matTooltip"
                      matTooltip="Select a record and click the edit icon on the left to edit the options"
                      matTooltipPosition="above">
                      help
                    </span>
                  </div>
                </td>
              </mat-expansion-panel-header>
              <p>
                <mat-form-field appearance="outline">
                  <mat-label>Comment</mat-label>
                  <input matInput [value]="row.comment" disabled="true" id="comment"
                    placeholder="No comment provided...">
                </mat-form-field>
              </p>
              <p>
                <mat-list *ngIf="row.files?.length > 0">
                  <sao-options-file [assignedSaoOption]="row">
                  </sao-options-file>
                </mat-list>
              </p>
            </mat-expansion-panel>
          </mat-accordion>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedAcqOptCols"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedAcqOptCols;"></tr>
      </table>
    </div>
  </div>
</div>
