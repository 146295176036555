<div class="access-conatiner">
    <mat-card-content class="matcard-container">
        <div class="full-width-image-container">
            <div class="image-container">
                <img alt="OSDU image" src="../../../assets/OSDU.png" />
            </div>
        </div>
        <p>
            Cannot see the data in the system? It is possible that you do not have access to the correct data group in
            OSDU.
            <br>
            To access the particular Business Unit data group in OSDU please contact OSDU Department Managers listed in
            the table below.
        </p>
        <p>
            <b>If you would like to request access to data from a business unit other than what you belong to you have
                to provide approval from the BU representative.</b>
        </p>
        <div class="mat-table-static">
            <div class="mat-header-row-static">
                <div class="mat-header-cell-static">Bussines Unit</div>
                <div class="mat-header-cell-static">Data groups</div>
                <div class="mat-header-cell-static">Department Managers</div>
            </div>
            <div class="mat-row-static">
                <div class="mat-cell-static">MCBU</div>
                <div class="mat-cell-static">data.mcbu.owners<br>data.mcbu.viewers</div>
                <div class="mat-cell-static">
                    
                    <a href="mailto:Matt.Worthy@chevron.com
                    ?subject={{mcbuSubject}}
                    &body={{mcbuMessageContentMatt}}
                    &cc={{mcbuCcLeslie}}">Matt Worthy</a><br>

                    <a href="mailto:LeslieMoore@chevron.com
                    ?subject={{mcbuSubject}}
                    &body={{mcbuMessageContentLeslie}}
                    &cc={{mcbuCcMatt}}">Leslie Moore</a>

                </div>
            </div>
        </div>
    </mat-card-content>
</div>