import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IAppState } from 'src/app/store/reducers';
import { LoginState } from 'src/app/store/reducers/login.reducer';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sao-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  private loginState$: Observable<LoginState>;
  public isUserLoggedIn: boolean = false;

  public updateDate: Date = new Date(2024, 2, 4);
  public departmentUrl: string = "https://etc.chevron.com/departments/tc/"
  public departmentName: string = "ETC/TC"
  public feedbackFormUrl: string = "";
  public contentContactName: string = "Jeremy Prouhet"
  public contentContactUrl: string = "mailto:Jeremy.Prouhet@chevron.com"
  public technicalContactName: string = "Valkyrie Team"
  public technicalContactUrl: string = "mailto:SS-DnI-Krenim-Technical@chevron.com"
  public version: string = "1.3.1";
  public environmentName: string = environment.environmentName;

  constructor(store: Store<IAppState>){
    this.loginState$ = store.pipe(select('loginState'));
  }

  ngOnInit(): void {
    let sub = this.loginState$.subscribe(loginState => {
      if(loginState == LoginState.LOGGEDIN) {
        this.isUserLoggedIn = true;
      } else {
        this.isUserLoggedIn = false;
      }
    })

    this.subscriptions.push(sub);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => {
      sub.unsubscribe();
    })
  }
}
